import axios from '@/plugins/axiosV2'

const http = axios(process.env.VUE_APP_SERVICIOS_API, (config) => {
  delete config.headers.Authorization;
  console.log('editar config', config.headers);

  config.url = config.url+'/'+process.env.VUE_APP_SERVICIOS_TOKEN;

  return config;
})

export default {
  cargar_documento(id, payload) {
    return http.post(`/solicitud/${id}/uploadfile`, payload);
  }
}